/* =========
  Countdown Timer For Squarespace
========== */

[data-wm-plugin="countdown-timer"] {
  --cd-countdown-layout: inline-flex;
  
  --cd-divider-color: var(--tweak-text-block-background-color);
  --cd-divider-opacity: 0.2;
  --cd-divider-height: 40%;
  --cd-divider-width: 2px;
  --cd-divider-display: block;
  --cd-divider-position: relative;

  --cd-digit-desktop-font-size: 2.6em;
  --cd-digit-mobile-font-size: 1.1em;
  --cd-text-mobile-font-size: 1.1em;
  --cd-text-desktop-font-size: 2.6em;

  --cd-bubble-background-color: none;
  --cd-digit-color: inherit;
  --cd-digit-padding: 0px;
  --cd-digit-margin: 0px;
  --cd-bubble-border-radius: 0px;

  --cd-border-width: 0px;
  --cd-border-color: var(--tweak-text-block-background-color);
  --cd-border-radius: 0px;
  --cd-section-gap: 0px;

  --cd-announcement-desktop-font: 0.4em;
  --cd-announcement-mobile-font: 0.8em;
  --cd-announcement-margin-left: 0px;

  --cd-colon-font-size: 1.2em;
  --cd-colon-display: none;

  --cd-text-color: inherit;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

[data-wm-plugin="countdown-timer"] .digits > * {
  font-size: var(--cd-digit-desktop-font-size);
  color: var(--cd-digit-color);
  margin: 0px;
}

[data-wm-plugin="countdown-timer"] .text > * {
  font-size: var(--cd-text-desktop-font-size);
  margin: 0px;
  color: var(--cd-text-color);
}

@media only screen and (max-width: 766px) {
  [data-wm-plugin="countdown-timer"] .digits > * {
    font-size: var(--cd-digit-mobile-font-size);
  }

  [data-wm-plugin="countdown-timer"] .text > * {
    font-size: var(--cd-text-mobile-font-size);
  }
}



[data-wm-plugin="countdown-timer"] .digits, [data-wm-plugin="countdown-timer"] .text > * {
  display: flex;
  align-items: center;
  justify-content: center;
}


[data-wm-plugin="countdown-timer"] .divider {
  width: var(--cd-divider-width);
  background: var(--cd-divider-color);
  opacity: var(--cd-divider-opacity);
  margin-left: 7px;
  margin-right: 7px;
  
  height: var(--cd-divider-height);
  align-self: center;
  display: var(--cd-divider-display);
  position: var(--cd-divider-position);
  align-items: center;
  justify-content: center;
}


[data-wm-plugin="countdown-timer"] .countdown-section {
  display: var(--cd-countdown-layout);
  gap: 5px;
}


[data-wm-plugin="countdown-timer"]  .text {
  align-self: center;
}

/** Bubble Styling **/
[data-wm-plugin="countdown-timer"] .digits {
  background: var(--cd-bubble-background-color);
  margin: var(--cd-digit-margin);
  padding: var(--cd-digit-padding);
  border-radius: var(--cd-bubble-border-radius);
}


/** Border Styling **/
[data-wm-plugin="countdown-timer"] .countdown-section {
  border: var(--cd-border-width) solid var(--cd-border-color);
  border-radius: var(--cd-border-radius);
  margin: var(--cd-section-gap) !important;
}
[data-wm-plugin="countdown-timer"] .text {
  padding: 0 var(--cd-digit-padding) var(--cd-digit-padding);
}

/** Colon Styling **/
div[data-wm-plugin="countdown-timer"] .divider .colon {
  display: var(--cd-colon-display);
  line-height: normal;
  align-self: baseline;
}

/** Announcement Bar **/
.announcement-countdown [data-wm-plugin="countdown-timer"] {
  --cd-divider-color: var(--announcement-bar-text-color);
  --cd-divider-display: block;
  --cd-divider-opacity: 0.2;
  --cd-divider-height: 40%;
  --cd-divider-width: 2px;
  
  --cd-countdown-layout: inline-flex;
  --cd-bubble-background-color: none;
  --cd-digit-color: var(--announcement-bar-text-color);
  --cd-digit-padding: 0px;
  --cd-digit-margin: 0px;
  --cd-bubble-border-radius: 0px;
  --cd-text-color: var(--announcement-bar-text-color);
  --cd-border-width: 0px;
  --cd-border-color: var(--tweak-text-block-background-color);
  --cd-border-radius: 0px;
  --cd-section-gap: 0px;

}

div.announcement-countdown {
  font-size: var(--cd-announcement-mobile-font);
  margin-left: var(--cd-announcement-margin-left);
}

@media only screen and (min-width: 767px) {
  div.announcement-countdown {
    font-size: var(--cd-announcement-desktop-font);
}
  
}



@media only screen and (max-width: 766px) {
  div[data-wm-plugin="countdown-timer"] .divider .colon {
    font-size: var(--cd-digit-mobile-font-size);
  }
}

@media only screen and (min-width: 767px) {
  div[data-wm-plugin="countdown-timer"] .divider .colon {
    font-size: var(--cd-digit-desktop-font-size);
  }
}

div[data-wm-plugin="countdown-timer"][data-loading-state ="loading"] {
  opacity: 0;
}

div[data-wm-plugin="countdown-timer"][data-loading-state ="complete"] {
  opacity: 1;
}

.sqs-edit-mode-active .code-block [data-wm-plugin="countdown-timer"] {
  color: transparent;
  background: transparent;
  font-size: 0;
  position: absolute;
  line-height: 0;
  width: 100%;
  height: 25px;
  border: 1px dashed var(--headingLargeColor);
}

/*Error Message*/
.show-in-editor {
  display:none;
}
.sqs-edit-mode .show-in-editor {
  display: block;
}

.sqs-edit-mode-active .code-block [data-wm-plugin="countdown-timer"]::after {
  content: 'Countdown Timer';
  color: var(--headingLargeColor);
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform:translateX(25%);
}
